import React, { useEffect, useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../config/config";
import { toast } from "react-toastify";

ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement
);

function WellnessDashboard() {
  const [activeTab, setActiveTab] = useState("factors");
  const { customerId } = useParams(); // Get customerId from URL
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDemoDataNotification, setshowDemoDataNotification] =
    useState(false);

  console.log("showDemoDataNotification", showDemoDataNotification);
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setUTCDate(thirtyDaysAgo.getUTCDate() - 30); // Jan 29, 2025

  // State for date inputs
  const [startDate, setStartDate] = useState(
    thirtyDaysAgo.toISOString().split("T")[0]
  ); // "2025-01-29"
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]); // "2025-02-28"

  console.log("dashboardData", dashboardData);
  useEffect(()=>{
    if(!customerId){
      setshowDemoDataNotification(true);
    }
  },[])

  useEffect(() => {
    const fetchData = async () => {
      if (!customerId) return;

      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/wellness/quiz/get/date`,
          {
            customerId,
            startdate: startDate,
            enddate: endDate,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.data?.success === true) {
          setDashboardData(response?.data?.entries);
        } else {
          setshowDemoDataNotification(true);
          toast.dismiss();
          toast.error(response?.data?.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [customerId, startDate, endDate]); // Re-run effect when customerId, startDate, or endDate change

  function generateLineChartData(supplementData) {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0); // Today: 2025-02-28T00:00:00.000Z

    // Generate labels and dates for 7 days including today (Feb 22–28)
    const labels = [];
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      // 6 days back + today
      const date = new Date(currentDate);
      date.setUTCDate(date.getUTCDate() - i);
      labels.push(date.toLocaleDateString("en-US", { weekday: "short" }));
      dates.push(date);
    }

    // Normalize supplement data dates
    const normalizedData = supplementData?.map((entry) => {
      const date = new Date(entry.date);
      date.setUTCHours(0, 0, 0, 0);
      return { date, supplement: entry.result?.supplement };
    });

    // Map to -1, 0, 1 only
    const supplementValues = dates?.map((date) => {
      const entry = normalizedData.find(
        (d) => d.date.getTime() === date.getTime()
      );
      if (!entry || entry.supplement === null || entry.supplement === undefined)
        return 0;
      return entry.supplement.toLowerCase() === "yes" ? 1 : -1;
    });

    return {
      labels: labels, // ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"]
      datasets: [
        {
          label: "Supplement Usage",
          data: supplementValues, // Only -1, 0, 1
          borderColor: "green",
          backgroundColor: "rgba(0, 128, 0, 0.2)",
          fill: true,
          tension: 0.3,
          // stepped: 'middle' // Optional: Makes the line step between points
        },
      ],
    };
  }

  const lineChartData = generateLineChartData(dashboardData);

  const factorsData = {
    labels: ["Factor A", "Factor B", "Factor C", "Factor D"],
    datasets: [
      {
        label: "Impact Score",
        data: [65, 59, 80, 81],
        backgroundColor: ["red", "orange", "purple", "blue"],
      },
    ],
  };

  const trendsData = {
    labels: ["Jan", "Feb", "Mar", "Apr"],
    datasets: [
      {
        label: "Trend Score",
        data: [45, 72, 58, 90],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };
  const barChartData = activeTab === "factors" ? factorsData : trendsData;

  // Generate Random Colors for Monthly Progress

  // const generateRandomColor = () => {
  //   const colors = ["bg-green-500", "bg-yellow-500", "bg-red-500"];
  //   return colors[Math.floor(Math.random() * colors?.length)];
  // };

  function calculateStreak(data) {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0); // Normalize to midnight UTC

    // Map to include both date and supplement, then sort by date descending
    const entries = data
      ?.map((entry) => {
        const date = new Date(entry.date);
        date.setUTCHours(0, 0, 0, 0);
        return { date, supplement: entry?.result?.supplement };
      })
      ?.sort((a, b) => b.date - a.date);

    if (!entries || entries.length === 0) return 0;

    let streak = 0;
    let previousDate = currentDate;

    for (let i = 0; i < entries.length; i++) {
      const currentEntry = entries[i];
      const diffInDays =
        (previousDate - currentEntry.date) / (1000 * 60 * 60 * 24);

      // Handle case sensitivity by converting to lowercase
      const supplementValue = currentEntry.supplement?.toLowerCase();

      // Debugging (optional)
      console.log("Supplement:", supplementValue);

      if (diffInDays === 0 && supplementValue === "yes") {
        streak = 1; // Start streak if today has supplement = "yes"
      } else if (diffInDays === 1 && supplementValue === "yes") {
        streak++; // Increment streak only if consecutive and supplement = "yes"
        previousDate = currentEntry.date;
      } else {
        break; // Break on gap > 1 day or supplement !== "yes"
      }
    }

    return streak;
  }

  const streak = calculateStreak(dashboardData);

  // Function to get the number of days in the current month
  function getDaysInMonth() {
    const now = new Date(Date.now());
    const year = now.getFullYear();
    const month = now.getMonth(); // 0-based (0 = Jan, 1 = Feb, etc.)
    return new Date(year, month + 1, 0).getDate(); // Last day of the current month
  }

  // Function to get background color based on date and data
  // function getDayColor(day, data) {
  //     console.log("day",day)
  //     console.log("data",data)
  //     const now = new Date(Date.now());
  //     const year = now.getFullYear();
  //     const month = now.getMonth();
  //     const checkDate = new Date(Date.UTC(year, month, day));
  //     checkDate.setUTCHours(0, 0, 0, 0);

  //     const entry = data.find((item) => {
  //         const itemDate = new Date(item.date);
  //         itemDate.setUTCHours(0, 0, 0, 0);
  //         return itemDate.getTime() === checkDate.getTime();
  //     });

  //     if (entry) {
  //         return entry.complete === true ? "bg-green-500" : "bg-yellow-500";
  //     }
  //     return "bg-red-500"; // No entry for this date
  // }

  const getDayColor = (date, data) => {
    const entry = data.find((d) => {
      const entryDate = new Date(d.date);
      entryDate.setUTCHours(0, 0, 0, 0);
      return entryDate.getTime() === date.getTime();
    });

    if (!entry || !entry.result?.supplement) return "bg-slate-500"; // No data
    return entry.result.supplement.toLowerCase() === "yes"
      ? "bg-green-500"
      : "bg-red-500";
  };

  const daysInMonth = getDaysInMonth();

  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0); // Today: 2025-02-28T00:00:00.000Z

  // Generate array of 30 days ending at today
  const last30Days = Array.from({ length: 30 }, (_, i) => {
    const date = new Date(currentDate);
    date.setUTCDate(date.getUTCDate() - (29 - i)); // Jan 30 to Feb 28
    return date;
  });

  function calculateAdherenceRate(data) {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0); // Today: 2025-02-28T00:00:00.000Z

    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setUTCDate(thirtyDaysAgo.getUTCDate() - 29); // 2025-01-30T00:00:00.000Z (30 days total)

    // Map entries within the last 30 days and normalize dates
    const relevantEntries = data
      ?.map((entry) => {
        const date = new Date(entry.date);
        date.setUTCHours(0, 0, 0, 0);
        return { date, supplement: entry?.result?.supplement };
      })
      .filter(
        (entry) => entry.date >= thirtyDaysAgo && entry.date <= currentDate
      );

    console.log("relevantEntries", relevantEntries);

    if (!relevantEntries || relevantEntries.length === 0) return 0;

    // Get unique days where supplement === "yes" (case-insensitive)
    const yesDays = new Set(
      relevantEntries
        .filter((entry) => entry.supplement?.toLowerCase() === "yes")
        .map((entry) => entry.date.getTime())
    );

    console.log("yesDays", yesDays.size);

    const totalDays = 30;
    const adherenceRate = (yesDays.size / totalDays) * 100;

    return Math.round(adherenceRate); // Round to nearest integer
  }
  const adherence = calculateAdherenceRate(dashboardData);

  console.log("2222", customerId);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex  w-full  justify-center items-center relative py-3  bg-[#2d3d23] text-white">
        <div className="w-11/12 relative gap-2  flex items-center justify-center ">
          {/* logo */}
          <img
            src={`https://thehealthspanco.com/cdn/shop/files/Logo_280x100px.png?v=1706525837&width=240`}
            className={` w-24 lg:w-32   brightness-[15000%] cursor-pointer`}
            alt="logo"
            onClick={() => {
              window.location.href = "https://thehealthspanco.com/";
            }}
          />
          {/* <div className=' text-sm lg:text-base'>Weight & Waist Assessment.</div> */}
        </div>
      </div>
      <div className="max-w-6xl w-full flex flex-col justify-center items-center p-6 min-h-screen mx-auto">
        <div className="flex flex-col gap-4 md:flex-row justify-between w-full items-center mb-6">
          <div>
            <h1 className="text-xl lg:text-2xl xl:text-3xl font-bold self-start">
              Wellness Dashboard
            </h1>
          </div>
          <div className="flex gap-5 text-xs text-[#2d3d23]">
            <div className="px-4 py-2 border border-[#2d3d23] rounded-lg">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={endDate} // Prevent start > end
                className="bg-transparent"
              />
            </div>
            <div className="px-4 py-2 border border-[#2d3d23] rounded-lg">
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate} // Prevent end < start
                max={today.toISOString().split("T")[0]} // No future dates
                className="bg-transparent"
              />
            </div>
          </div>
        </div>

        {showDemoDataNotification === true && (
          <div className="w-full text-lg md:text-xl lg:text-2xl text-[#2F412F] font-bold p-4 border-2 border-[#2F412F] rounded mb-3 items-center">
            This is the demo data, your data will look like this, Please take
            the quiz.
          </div>
        )}

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6 bg-white p-4 rounded-lg shadow w-full">
          <div className="bg-[#2F412F] text-white p-6 flex flex-col gap-1 md:gap-2 rounded-lg text-center">
            <h2 className="text-lg font-semibold">Current Streak</h2>
            <p className="text-3xl font-bold">{streak}</p>
            <p>days</p>
          </div>
          <div className="bg-[#2F412F] text-white p-6 flex flex-col gap-1 md:gap-2 rounded-lg text-center">
            <h2 className="text-lg font-semibold">Adherence Rate</h2>
            <p className="text-3xl font-bold">{adherence}%</p>
            <p>this month</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
          <div className="bg-white p-4 rounded-lg shadow h-[450px] flex flex-col">
            <h2 className="text-xl font-bold mb-5 text-[#2F412F]">
              Wellness Overview
            </h2>
            <div className="flex-1">
              <Line
                data={lineChartData}
                options={{ maintainAspectRatio: false, responsive: true }}
              />
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow h-[450px] flex flex-col">
            <h2 className="text-xl font-bold mb-5 text-[#2F412F]">
              Sleep Quality Analysis
            </h2>
            <div className="flex gap-4 mb-4">
              <button
                className={`px-4 py-2  hover:bg-white font-semibold border-b-2 ${
                  activeTab === "factors"
                    ? " border-[#2F412F] text-[#2F412F]"
                    : "text-gray-600 border-white"
                }`}
                onClick={() => setActiveTab("factors")}
              >
                Factors
              </button>
              <button
                className={`px-4 py-2  hover:bg-white font-semibold border-b-2  ${
                  activeTab === "trends"
                    ? " border-[#2F412F] text-[#2F412F]"
                    : "text-gray-600 border-white"
                }`}
                onClick={() => setActiveTab("trends")}
              >
                Trends
              </button>
            </div>
            <div className="flex-1">
              <Bar
                data={barChartData}
                options={{ maintainAspectRatio: false, responsive: true }}
              />
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-5 text-[#2F412F]">
              Wellness Activities
            </h2>

            <div className="grid grid-cols-2 gap-2">
              {[
                "Deep breathing",
                "Meditation",
                "Desk stretches",
                "Jumping jacks",
              ].map((activity) => (
                <div
                  key={activity}
                  className="bg-gray-200 p-4 rounded-md text-center"
                >
                  <p className="text-xl font-bold">10</p>
                  <p>{activity}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Monthly Progress & Key Insights */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 w-full">
          {/* <div className="bg-white p-4 rounded-lg shadow">
                        <h2 className="text-xl font-bold mb-5 text-[#2F412F]">Monthly Progress</h2>
                        <div className="grid grid-cols-6 md:grid-cols-7 gap-1 text-white text-center">
                            {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => (
                                <div
                                    key={day}
                                    className={`p-2 rounded ${getDayColor(day, dashboardData)} `}
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                    </div> */}
          <div className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-5 text-[#2F412F]">
              Last 30 Days Progress
            </h2>
            <div className="grid grid-cols-6 md:grid-cols-7 gap-1 text-white text-center">
              {last30Days?.map((date, index) => (
                <div
                  key={index}
                  className={`p-2 rounded ${getDayColor(date, dashboardData)}`}
                >
                  {date.getUTCDate()}{" "}
                  {/* Displays day number: 30, 31, 1, ..., 28 */}
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-5 text-[#2F412F]">
              Key Insights
            </h2>
            <ul className="list-disc pl-5 text-left w-full">
              <li className="border-b-2 border-gray-100 text-left mb-0">
                Your energy levels are highest after morning meditation
              </li>
              <li className="border-b-2 border-gray-100 text-left mb-0">
                Sleep quality improves significantly on caffeine-free days
              </li>
              <li className="border-b-2 border-gray-100 text-left mb-0">
                Stress-related sleep issues tend to peak mid-week
              </li>
              <li className="border-b-2 border-gray-100 text-left mb-0">
                Physical activities show strong correlation with improved mood
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fixed bottom-4 left-4 z-50">
        <a
          href="https://api.whatsapp.com/send/?phone=918431335947&text=Hi%2C%0AI%27m+interested+in+learning+more+about+your+health+products+and+weight+loss+plan.&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
          className="block w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20"
        >
          <img
            src="/whatsappLogo.png"
            alt="Chat on WhatsApp"
            className="w-full h-full object-contain"
          />
        </a>
      </div>
    </div>
  );
}

export default WellnessDashboard;
