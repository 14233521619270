import React from 'react'
import Quiz from './Quiz'
import { DailyHealthSpendProvider } from './context/appContext'

const Daily_Main = () => {
  return (
    // <DailyHealthSpendProvider>{<App/>}</DailyHealthSpendProvider>
    <DailyHealthSpendProvider>{<Quiz/>}</DailyHealthSpendProvider>
  )
}

export default Daily_Main