import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "./context/appContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../config/config";
import { toast } from "react-toastify";

const Quiz = () => {
  const [searchParams] = useSearchParams();
  const cid = searchParams.get("cid");
  const { answers, setAnswers } = useContext(AppContext);
  const [currentQuestionId, setCurrentQuestionId] = useState("supplement");
  const [showSummary, setShowSummary] = useState(false);
  const navigate = useNavigate();
  const [streak, setStreak] = useState(0);
  const [reminder, setReminder] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [quizData, setQuizData] = useState("");
  const [isCompleteWithinTime, setIsCompleteWithinTime] = useState(false);

  console.log("quizData", quizData);
  console.log("isCompleteWithinTime", isCompleteWithinTime);

  console.log("customerId", customerId);
  console.log("cid", cid);

  const TOKEN = cid;
  // const TOKEN =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiOTIzMzkzMDA5Mjg0MiIsImRhdGUiOiIyMDI1LTA0LTAxVDA1OjExOjMxLjM1M1oiLCJleHAiOjE3NDM1ODA3NDAsImlhdCI6MTc0MzQ4NDI5MX0.tA3xA2hsxuHPlw1_eZEtgCywuna7EryJJ0Wqk9iki9o";

  const submitQuizResults = async (currentAnswers) => {
    const quizData = {
      token: TOKEN,
      result: {
        ...currentAnswers,
      },
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/wellness/quiz/create`,
        quizData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      //   console.log("Quiz results submitted successfully:", response?.data);
      if (response?.data.success) {
        setCustomerId(response?.data?.quizupdate?.customerId);
        setQuizData(response?.data?.quizupdate);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message);
      console.error(
        "Error submitting quiz results:",
        error.response?.data || error.message
      );
    }
  };

  const checkMilestone = () => {
    console.log("Milestone checked for streak:", streak);
  };

  const setCustomReminder = () => {
    console.log("Custom reminder set");
  };

  const reorderSupplement = () => {
    console.log("Reordering supplement...");
  };

  const trackProgress = () => {
    console.log("Tracking progress...");
  };

  const showAction = (message) => {
    console.log("Action:", message);
  };

  // useEffect(() => {
  //   submitQuizResults();
  // }, []);

  useEffect(() => {
    if (quizData && quizData.date && quizData.complete) {
      // Get yesterday's 8 PM
      const yesterday8PM = new Date();
      yesterday8PM.setDate(yesterday8PM.getDate() - 1);
      yesterday8PM.setHours(20, 0, 0, 0); // Set to 8 PM

      // Get today's 8 PM
      const today8PM = new Date();
      today8PM.setHours(20, 0, 0, 0); // Set to 8 PM

      // Convert quiz date from string to Date object
      const quizDate = new Date(quizData.date);

      // Check if quiz date falls between yesterday 8 PM and today 8 PM
      // and if complete is true
      const isWithinTimeRange =
        quizDate >= yesterday8PM &&
        quizDate <= today8PM &&
        quizData.complete === true;

      setIsCompleteWithinTime(isWithinTimeRange);
    }
  }, []); // Run effect when quizData changes

  // const handleSelect = (questionKey, answer) => {
  //   const selectedOption = questions[questionKey]?.options.find(
  //     (option) => option.label === answer
  //   );

  //   if (selectedOption?.action) {
  //     selectedOption.action();
  //   }

  //   // Update answers and submit with the new state immediately
  //   setAnswers((prev) => {
  //     const newAnswers = { ...prev, [questionKey]: answer };
  //     if (!showSummary) {
  //       submitQuizResults(newAnswers); // Pass the updated answers directly
  //     }
  //     return newAnswers;
  //   });

  //   if (selectedOption?.nextId) {
  //     setTimeout(() => setCurrentQuestionId(selectedOption.nextId), 200);
  //   } else {
  //     setShowSummary(true);
  //   }
  // };

  const handleSelect = async (questionKey, answer) => {
    const selectedOption = questions[questionKey]?.options.find(
      (option) => option.label === answer
    );

    if (selectedOption?.action) {
      selectedOption.action();
    }

    const updatedAnswers = { ...answers, [questionKey]: answer };

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/wellness/quiz/create`,
        {
          token: TOKEN,
          result: updatedAnswers,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data.success) {
        setAnswers(updatedAnswers);
        setCustomerId(response?.data?.quizupdate?.customerId);
        if (selectedOption?.nextId) {
          setTimeout(() => setCurrentQuestionId(selectedOption.nextId), 200);
        } else {
          setShowSummary(true);
        }
      } else {
        toast.error(response?.data?.message || "Failed to save quiz progress.");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message || "An error occurred.");
      console.error(
        "Error submitting quiz results:",
        error.response?.data || error.message
      );
    }
  };

  const restartQuiz = () => {
    setAnswers({});
    setCurrentQuestionId("supplement");
    setShowSummary(false);
  };

  const handleGoToDashboard = () => {
    console.log("1113", customerId);
    navigate(`/wellness-dashboard/${customerId}`);
  };

  const questions = {
    supplement: {
      text: "Did you take your THC supplement today?",
      options: [
        {
          label: "Yes",
          nextId: "feeling",
          action: () => {
            setStreak(streak + 1);
            checkMilestone();
          },
        },
        {
          label: "No",
          nextId: "missedReason",
          action: () => {
            setStreak(0);
            checkMilestone();
          },
        },
      ],
    },
    missedReason: {
      text: "What made you miss your supplement today?",
      options: [
        { label: "Forgot", nextId: "reminderSuggestion" },
        { label: "Ran out", nextId: "reorderPrompt" },
        { label: "Didn't feel like it", nextId: "motivationCheck" },
        { label: "Too busy", nextId: "routineCheck" },
      ],
    },
    reminderSuggestion: {
      text: "Would you like to set up a daily reminder?",
      options: [
        { label: "Yes, please", nextId: "reminderTime" },
        { label: "No, thanks", nextId: "feeling" },
      ],
    },
    reminderTime: {
      text: "When's the best time to remind you?",
      options: [
        { label: "Custom Time", nextId: "customReminderTime" },
        {
          label: "Morning (8 AM)",
          nextId: "feeling",
          action: () => setReminder("8:00 AM"),
        },
        {
          label: "Evening (8 PM)",
          nextId: "feeling",
          action: () => setReminder("8:00 PM"),
        },
      ],
    },
    customReminderTime: {
      text: "Enter your preferred reminder time:",
      options: [
        {
          label: "Confirm",
          nextId: "feeling",
          action: () => setCustomReminder(),
        },
      ],
    },
    reorderPrompt: {
      text: "Would you like to reorder now?",
      options: [
        {
          label: "Yes, reorder",
          nextId: "feeling",
          action: () => reorderSupplement(),
        },
        { label: "Later", nextId: "feeling" },
      ],
    },
    motivationCheck: {
      text: "What's making it difficult to stay motivated?",
      options: [
        { label: "Not seeing results", nextId: "resultsTip" },
        { label: "Uncertain about benefits", nextId: "showEducation" },
      ],
    },
    resultsTip: {
      text: "Results take time. Would you like to track progress?",
      options: [
        {
          label: "Start tracking",
          nextId: "feeling",
          action: () => trackProgress(),
        },
        { label: "Maybe later", nextId: "feeling" },
      ],
    },
    showEducation: {
      text: "Here's a quick guide to THC.",
      videoUrl: "https://www.youtube.com/watch?v=GJaJQ3sFnzs",
      options: [{ label: "Done!", nextId: "feeling" }],
    },
    feeling: {
      text: "How are you feeling today?",
      options: [
        { label: "Energetic", nextId: "activityPrompt" },
        { label: "Balanced", nextId: "activityPrompt" },
        { label: "Low", nextId: "lowEnergyReason" },
      ],
    },
    lowEnergyReason: {
      text: "What's making you feel low today?",
      options: [
        { label: "Mental stress", nextId: "mentalWellness" },
        { label: "Physical fatigue", nextId: "physicalWellness" },
        { label: "Poor sleep", nextId: "sleepCheck" },
      ],
    },
    mentalWellness: {
      text: "Try a quick mental wellness exercise:",
      options: [
        {
          label: "Deep breathing",
          nextId: "activityPrompt",
          action: () =>
            showAction("Breathe in for 4 seconds, hold for 4, exhale for 6."),
        },
        { label: "Skip for now", nextId: "activityPrompt" },
      ],
    },
    physicalWellness: {
      text: "Try a quick physical wellness exercise:",
      options: [
        { label: "10 desk stretches", nextId: "activityPrompt" },
        { label: "5-minute meditation", nextId: "activityPrompt" },
        { label: "15 jumping jacks", nextId: "activityPrompt" },
      ],
    },
    sleepCheck: {
      text: "What's keeping you up?",
      options: [
        { label: "Stress", nextId: "" },
        { label: "Anxiety", nextId: "" },
        { label: "Pain", nextId: "" },
        { label: "Other", nextId: "caffeineCheck" },
      ],
    },
    caffeineCheck: {
      text: "Any caffeine after 2 PM?",
      options: [
        { label: "Yes", nextId: "" },
        { label: "No", nextId: "" },
      ],
    },
    activityPrompt: {
      text: "Ready for today's wellness activity?",
      options: [
        { label: "Show me", nextId: "activity" },
        { label: "Skip today", nextId: null },
      ],
    },
    activity: {
      text: "Try this quick wellness boost:",
      getCustomContent: () => {
        const activities = [
          "10 desk stretches",
          "5-minute meditation",
          "15 jumping jacks",
          "5 rounds of tour of your house/ office.",
        ];
        return activities[Math.floor(Math.random() * activities.length)];
      },
      options: [
        {
          label: "Done!",
          nextId: null,
          action: () => showAction("Great job! Keep it up!"),
        },
        { label: "Skip", nextId: null },
      ],
    },
  };

  const currentQuestion = questions[currentQuestionId];

  return (
    <div className="h-screen w-full bg-gray-100 flex flex-col overflow-y-auto">
      <header className="bg-[#2F412F] sticky top-0 z-50 h-24 flex items-center justify-between p-5">
        <div className="text-white text-lg md:text-2xl font-bold">
          The HealthSpan Co.
        </div>
        <button
          onClick={() =>
            (window.location.href = "https://thehealthspanco.com/")
          }
          className="bg-white px-3 md:px-4 py-1 md:py-2 text-xs md:text-base rounded text-red-600"
        >
          Exit
        </button>
      </header>

      {isCompleteWithinTime ? (
        <div className="flex items-center justify-center h-full  bg-gray-100">
          <div className="bg-white shadow-sm rounded-2xl border border-[#2d3d23] p-6 max-w-lg text-center">
            <h1 className="text-3xl font-bold text-[#2d3d23] mb-4">
              You have completed the quiz!
            </h1>
            <p className="text-gray-600 mb-6">
              Now, go to the dashboard page to see your results.
            </p>
            <button
              onClick={handleGoToDashboard}
              className="bg-[#2d3d23] hover:bg-[#6d4137] text-white font-medium px-6 py-3 rounded-lg transition duration-300"
            >
              Go to Wellness Dashboard
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-1 justify-center items-center p-4 md:p-6">
          <div className="w-full max-w-lg md:max-w-xl lg:max-w-2xl bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="bg-[#2d3d23] text-white text-center p-4 font-bold text-lg">
              {showSummary ? "Summary" : "Daily Wellness Check"}
            </div>
            <div className="p-6">
              {showSummary ? (
                <div>
                  <h2 className="text-lg font-semibold text-center mb-4">
                    Today's Summary
                  </h2>
                  <table className="w-full border border-collapse">
                    <tbody>
                      {Object.entries(answers).map(([key, value]) => (
                        <tr key={key} className="border">
                          <td className="p-2 border">{questions[key]?.text}</td>
                          <td className="p-2 border">
                            {value || "No response"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={restartQuiz}
                      className="bg-[#2d3d23] hover:bg-[#6d4137] text-white px-4 py-2 rounded-md mr-4"
                    >
                      Restart
                    </button>
                    <button
                      onClick={handleGoToDashboard}
                      className="bg-[#2d3d23] hover:bg-[#6d4137] text-white px-4 py-2 rounded-md"
                    >
                      Go to Wellness Dashboard
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="text-gray-600 text-sm flex justify-between mb-2">
                    <p>Wellness Streak</p>
                    <p>🏆 {streak} days</p>
                  </div>
                  <h2 className="text-lg font-semibold text-center mb-4">
                    {currentQuestion?.text}
                  </h2>
                  <div className="flex flex-col gap-2">
                    {currentQuestion?.options.map((option, index) => (
                      <button
                        key={index}
                        onClick={() =>
                          handleSelect(currentQuestionId, option.label)
                        }
                        className="bg-[#2d3d23] hover:bg-[#6d4137] text-white px-4 py-2 rounded-md"
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quiz;
