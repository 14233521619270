import React from "react";
import Weight_Main from "../pages/weightQuiz/main";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Guts_Main from "../pages/gutQuiz/main";
import NotFound from "../pages/notFound";
import Daily_Main from "../pages/dailyQuiz/main";
import WellnessDashboard from "../pages/dailyQuiz/wellnessDashboard";

const Routers = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Screen />} /> */}
      <Route path="/" element={<Navigate to="/weight-management" />} />
      <Route path="/weight" element={<Navigate to="/weight-management" />} />
      <Route path="/gut" element={<Navigate to="/gut-health" />} />
      <Route path="/weight-management" element={<Weight_Main />} />
      <Route path="/gut-health" element={<Guts_Main />} />
      {/* <Route path="/daily-wellness-checkin" element={<Daily_Main />} /> */}
      <Route path="/daily-wellness-quiz" element={<Daily_Main />} />
      <Route path="/wellness-dashboard" element={<WellnessDashboard />} />
      <Route path="/wellness-dashboard/:customerId" element={<WellnessDashboard />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};



export default Routers;

const Screen = () => (
  <div className="h-screen flex justify-center items-center text-sm space-x-3">
    {/* <Link to="/weight"> */}
    <Link to="/weight-management">
      <button className="border p-2 px-5 rounded-md shadow-lg hover:bg-gradient-to-br from-green-800 hover:text-white">
        Weight Quiz
      </button>
    </Link>
    <Link to="/gut-health">
    {/* <Link to="/gut"> */}
      <button className="border p-2 px-5 rounded-md shadow-lg hover:bg-gradient-to-br from-green-800 hover:text-white">
        Gut Quiz
      </button>
    </Link>
  </div>
);
